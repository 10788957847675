// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-type-categories-category-group-categories-category-js": () => import("./../../../src/pages/{Categories.type}/{Categories.categoryGroup}/{Categories.category}.js" /* webpackChunkName: "component---src-pages-categories-type-categories-category-group-categories-category-js" */),
  "component---src-pages-categories-type-categories-category-group-js": () => import("./../../../src/pages/{Categories.type}/{Categories.categoryGroup}.js" /* webpackChunkName: "component---src-pages-categories-type-categories-category-group-js" */),
  "component---src-pages-categories-type-js": () => import("./../../../src/pages/{Categories.type}.js" /* webpackChunkName: "component---src-pages-categories-type-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-image-sharp-fields-data-type-image-sharp-fields-data-category-group-image-sharp-fields-data-category-image-sharp-fields-data-image-file-name-js": () => import("./../../../src/pages/{ImageSharp.fields__data__type}/{ImageSharp.fields__data__categoryGroup}/{ImageSharp.fields__data__category}/{ImageSharp.fields__data__imageFileName}.js" /* webpackChunkName: "component---src-pages-image-sharp-fields-data-type-image-sharp-fields-data-category-group-image-sharp-fields-data-category-image-sharp-fields-data-image-file-name-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

